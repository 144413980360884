<template>
    <section class="blockElement space bg-white mkpCamp blogsData">
        <div class="container">
            <div class="row" v-if="blogsData.length">
                <div class="col-12 col-md-6 mb-4" v-for="list,key in blogsData" :key="key">
                    <div class="card-body boxed">
                        <router-link :to="{name : 'blogDetail',params:{'title': list.slug ? list.slug : list.id}}" v-if="list.title && list.title.rendered"><h3 v-html="list.title.rendered"></h3></router-link>
                        <p class="f-14">{{formatDate(list.date)}}</p>
                        <p v-html="list.excerpt.rendered" v-if="list.excerpt && list.excerpt.rendered"></p>
                        <p v-else></p>
                        <router-link :to="{name : 'blogDetail',params:{'title': list.slug ? list.slug : list.id}}" class="secondary">{{$t('blog.text1')}}</router-link>
                    </div>
                </div>
                <div class="text-center">
                    <button class="button fillBtn zulu_btn mt-4 px-md-4" v-if="totalPages != pageItems" @click="loadMore()">{{$t('blog.text2')}}</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import moment from "moment";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                blogsData: [],
                pageItems: 1,
                limit: 10,
                totalPages: '',
            };
        },
        watch: {
            "$route.params.name"() {
                if (this.$route.name == "AllBlogs") {
                    this.pageItems = 1
                    this.getBlog('change')
                }
            },
        },
        computed: {},
        methods: {
            loadMore(){
                this.pageItems += parseInt(1)
                this.getBlog()
            },
            formatDate(date) {
                if (date) {
                    return moment(date).format("DD MMM YYYY");
                } else {
                    return "N/A";
                }
            },
            getBlogName() {
                let data = this.static_vars.BlogsArray.filter((i) => i.slug == this.$route.params.name);
                if (data.length) {
                    return data[0].name;
                } else {
                    return "";
                }
            },
            getBlog(change){
                let data = this.static_vars.BlogsArray.filter((i) => i.slug == this.$route.params.name);
                if (data.length) {
                    this.store.getBlogsData({}, true, data[0].id,this.limit,this.pageItems).then((response) => {
                        if (Object.keys(response).length) {
                            this.totalPages = response.headers['x-wp-totalpages']
                            if(change != 'change'){
                                this.blogsData = this.blogsData.concat(response.data);
                            } else {
                                this.blogsData = response.data;
                            }
                        }
                    });
                }
            }
        },
        mounted() {
            this.getBlog()
        }
    };
</script>